import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import './Header.css'

export default function Header() {

  let [showMenu, setShowMenu] = useState(false)

  function handleClick() {
    setShowMenu(!showMenu)
    document.body.style.overflowY = !showMenu ? 'hidden' : 'scroll'
  }

  return (
    <header className="page-primary">
      {/* <button className='menu-button' onClick={_ => handleClick()}><span/><span/><span/></button> */}
      
      <CSSTransition in={showMenu} unmountOnExit={true} timeout={300} classNames='menu-transition'>
        <div className='main-menu'>

        </div>
      </CSSTransition>

      { showMenu ? <div className='background-cover'/> : <></> }
      <div className="page-title-container">
        <h1>Ronan<br />Miguel<br />Kelly</h1>
        <h4>iOS Developer</h4>
        <a href="/ronanresume%20(1).pdf" target='blank'>View Resume</a>
      </div>

      <div className="dashed-line line1" />
      <div className="dashed-line line2" />
      <div className="dashed-line line3" />
      <div className="dashed-line line4" />
      <div className="dashed-line line5" />
      <div className="dashed-line line6" />

      <div id="scroll-down" />
    </header>
  );
}
