
import './Work.css'

interface Experience {
    name: string
    tags: string[]
    isCurrent: boolean
    description: string
}

export default function Work() {

    const experience:Experience[] = [
        {
            name: 'Amazon',
            tags: ["iOS", "Swift", "Objective-C"],
            isCurrent: true,
            description: 'iOS developer working on the Amazon Flex iOS application'
        },
        {
            name: 'Hilton Software',
            tags: ['iOS', 'SwiftUI', 'Swift', 'Objective-C', 'C#'],
            isCurrent: false,
            description: 'iOS developer working on the AeroApp and WingX applications for iPad and iPhone platforms. Implemented numerous features and modernization such as using SwiftUI for any new views and utilizing Swift features such as the Swift Network API and Combine in place of legacy code. Later on began to work on data processing tools using the .Net Core framework in addition to iOS. Work was split up into two week sprints using the Agile methodology'
        },
        {
            name: 'Loansmarter',
            tags: ['C#', '.Net', 'PHP', 'SQL'],
            isCurrent: false,
            description: 'Created website to create broker agent webpages dynamically. Integrated thrid-party APIs such as the CIC credit score API as well as updating the main website.'
        },
        {
            name: 'FDOT',
            tags: ['C#', '.Net', 'Web Development'],
            isCurrent: false,
            description: 'Re-created the FDOT internal website adding dynamic content rendering, day and night mode feature, as well as anadministrator page to add and subtract links and files. Mades heavy use of C# with the ASP .NET architecture for the backend features'
        }
    ]

    function renderExperience(exp:Experience, index:number) {
        return <div className={`exp ${index % 2 !== 0 ? 'exp-right' : ''}`}>
             <h3>{exp.name}{exp.isCurrent ? <span>Current</span> : <></>}</h3>
             <div className='tags-contain'>{exp.tags.map(t => <span>{t}</span>)}</div>
             <p>{exp.description}</p>
        </div>
    }

    return <div className='work-container'>
        <h2>Experience</h2>
        <p>Current and past work experience</p>
        <div style={{position: 'relative'}}>
            { experience.map(renderExperience) }
            <div className='exp-timeline'/>
        </div>
    </div>
}