
import React, { useState } from 'react'
import './About.css'

export default function About() {

    return <div className='about'>
        <div className='about-contain'>
            <h2>About Me</h2>
            <div className='bio-list'>
                <ul>
                    <li><span>Name</span> Ronan M. Kelly</li>
                    <li><span>Education</span> FIU</li>
                    <li><span>Languages</span> English and Spanish</li>
                </ul>
                <p>
                    I've been programming since highschool constantly learning and honing my skills 
                    to stay current with the ever-changing nature of the field. I currently work as a 
                    iOS developer and work on multiple data processing tools.
                </p>

                <a href='mailto:rkell046@fiu.edu' className='email'>Contact Me</a>
            </div>
        </div>
    </div>
}