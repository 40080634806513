
import './Links.css'

interface Link {
    name:string
    url:string 
    icon:string
}

export default function Links() {

    let links = [
        { name: 'Resume',   url: '/ronanresume%20(1).pdf', icon: '/icon+resume.png' },
        { name: 'Linkedin', url: 'https://www.linkedin.com/in/ronan-kelly-87812614a/', icon: '/icon+linkedin.png' },
        { name: 'Github',   url: 'https://github.com/rmiguelkelly', icon: '/icon+github.png' },
        { name: 'Kaggle',   url: 'https://www.kaggle.com/ronanmiguelkelly', icon: '/icon+kaggle.png' }
    ]

    function renderLink(link:Link) {
        return <a className='link-a' href={link.url} target='blank'>
            <div className='link' key={link.url}>
                <img alt={link.name} src={link.icon} className='url-icon'/>
                <h4>{link.name}</h4>
            </div>
        </a>
    }

    return <div className='body'>
        <div className='subbody'>
            <h2>Quick Links</h2>
            <div className='links-container'>
                { links.map(link => renderLink(link)) }
            </div>
        </div>
    </div>
}