import React, { useEffect, useState } from 'react';
import About from './Views/About';
import Footer from './Views/Footer';

import Header from './Views/Header';
import Links from './Views/Links';
import Skills from './Views/Skills';
import Work from './Views/Work';

import './App.css'

function App() {

    return <div>
        <Header/>
        <Links/>
        <About/>
        <Skills/>
        <Work/>
        <Footer/>
    </div>
}

export default App;
