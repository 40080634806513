
import { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import './Skills.css'


interface Skill {
    name: string,
    core: boolean
    description: string
}

export default function Skills() {

    let [skill, setSkill] = useState<Skill | undefined>(undefined)

    useEffect(() => {
        const scroll = !skill ? '' : 'hidden'
        document.body.style.overflow = scroll
        document.head.style.overflow = scroll
    })

    const skills:Skill[] = [
        { 
            name: 'Swift',
            core: true,
            description: 'Swift is one of my favorite languages due to how verbose it is compared to other strongly type languages. I love how it is protocol oriented but supports paradigms such as functional and reactive (Combine framework) which when paired frameworks like SwiftUI, makes for an extremely rapid and fun developement experience'
        },
        {
            name: 'C#',
            core: true,
            description: 'C# was one of my first languages I used in which really got me into programming. The rich feature set and pure object oriented nature of it makes it an amazing tool for console and web applications alike.'
        },
        {
            name: 'Objective-C',
            core: false,
            description: 'One of the first languages I used when I learned iOS development, its dynamic and low-level nature makes it an interesting but fun language to use especially when I understood it\'s history'
        },
        {
            name: 'SwiftUI',
            core: false,
            description: 'Ever since I have been creating UIs, I always programmed them in an imperative fashion manually updating the UI when state changed. SwiftUI introduced me to the declarative pattern of programming which was a total game changer due to how verbose it is and how easy it is to create user interfaces in so many less lines of code than UIKit. It also changes how you think about views and state management which goes together with the core concepts of functional programming'
        },
        {
            name: 'ReactJS',
            core: false,
            description: 'ReactJS was one of the first declarative UI frameworks I worked with and I instantly fell in love with how you can create recyclable views which handle their own internal state.'
        },
        {
            name: 'C',
            core: false,
            description: 'Many of the languages I listed are generally higher level. One of the reasons I love C is due to how it is lower level and as consequence, makes you understand how the computer works (ie. everything being a continous buffer of memory). Programming small projects in C such as mini web servers and data structures gives huge insite on how many things work \'under the hood\'. One thing I love to pair with C is network programming using just the Berkeley socket interface'
        },
        {
            name: 'Python',
            core: false,
            description: 'While strongly typed languages are better suited to larger enterprice applications in my opnion, I\'ve grown to appreciate the pros of Python\'s dynamic nature and verbose syntax. Lately I have been putting some time into studying machine learning and data science in which pythin is the defacto langauge'
        },
        {
            name: 'F#',
            core: false,
            description: 'F# is a newly discovered gem of a language for me as it is the first one I used when I started learning functional programming. While I thought the syntax was weired and the concepts where counter-intuitive (ie. using recursion instead of loops and having no mutable state), I grew to undestand the power behind it and how I can make programs that where more concise, safe, and much easier to debug. I try to implement the concepts I learned in F# to my other code such as making my functions as pure as possible which provides the same benifits'
        },
        {
            name: 'AWS',
            core: false,
            description: 'While not a language nor singular skill, I believe cloud computing technology is an integral part for not just DevOps but also developers of any flavor. Within cloud computing comes skills such as security and scalability to name a few. I used AWS for things like website hosting (this website for example uses just S3) as well as database services, serverless functions, EC2, and Route 53'
        }
    ]

    function renderSkill(skill:Skill) {
        return <div className={`skill ${skill.core ? 'skill-core' : ''}`} onClick={_ => setSkill(skill)}>{skill.name}</div>
    }

    return <div className='skills-body'>

        <h2>Languages and Technologies</h2>
        <h5>Hint: Click on one to learn more on my experiences</h5>

        <div className='skills-container'>
            { skills.map(renderSkill) }
        </div>
        <CSSTransition in={!!skill} timeout={300} classNames='screen-cover' unmountOnExit>
            <div className='screen-cover' onClick={_ => setSkill(undefined)}/>
        </CSSTransition>
        <CSSTransition in={!!skill} timeout={300} classNames='modal-cover' unmountOnExit>
            <div className='modal-cover'>
                <h4>{skill?.name}</h4>
                <p>{skill?.description}</p>
                <button onClick={_ => setSkill(undefined)}>Hide</button>
            </div>
        </CSSTransition>
    </div>
}